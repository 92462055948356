import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';

import "./Header.scss";
import {Link} from "react-router-dom";
import TopMenu from "../top-menu/TopMenu";
import Search from "../search/Search";

const Header = (props) => {
    const {content, search} = props;
    const [input, setInput] = useState('');
    
    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    };

    const IsShowSearchBar = () => {
        const pathName = usePathname();
        return pathName === '/';
    };

    if (content.length === 0) {
        return <></>;
    }

    const updateInput = async (input) => {
        search(input);
     };

    return <div className="header animate__animated animate__backInDown fixed">
        <TopMenu></TopMenu>
        <div className="main-bg" style={{backgroundImage: 'url("' + content['background'] + '")'}}/>
        <div className="top-container">
            <Link to={{
                pathname: `/`
            }}>
                <div className="logo" style={{backgroundImage: 'url("' + content['logo'] + '")'}}/>
            </Link>
            <div className="header">
                <div className="title">{content['channel_title']}</div>
                <div className="subtitle">{content['subscribers']}</div>
            </div>
            <div className="desktop-search">
            {(IsShowSearchBar() && <Search        
                    input={input} 
                    setKeyword={updateInput} 
            />)}
           </div>
            <a href={content['subscription']} className="subscribe">SUBSCRIBE</a>
            <a href="https://discord.com/invite/kaz7fFNpF4" className="subscribe">DISCORD</a>
        </div>
        <div className="mobile-search">
            {(IsShowSearchBar() && <Search
                    input={input} 
                    setKeyword={updateInput} 
            />)}
           </div>
    </div>
};

export default Header;
